import React from 'react'

const Thankyou = () => {
  return (
    <div>
      {/* <section className="login-main-wrapper" style={{width:"53%",margin:"auto"}}>
  <div className="main-container">
    <div className="login-process">
      <div className="login-main-container">
        <div className="thankyou-wrapper">
          <h1>
            <img
              src="http://montco.happeningmag.com/wp-content/uploads/2014/11/thankyou.png"
              alt="thanks"
            />
          </h1>
          <p  style={{textAlign:"center"}}>for contacting us, we will get in touch with you soon... </p>
         <div style={{margin:"auto",display: "flex",justifyContent:"center",marginTop:"30px"}}> <a className='btn-buy-thakyou'  href="/">Back to home</a></div>
          <div className="clr" />
        </div>
        <div className="clr" />
      </div>
    </div>
    <div className="clr" />
  </div>
</section> */}
<div className="header-custom email-signup-thankyou">
  <div className="content">
    <div className="left-hole" />
    <div className="right-hole" />
    <div className="main-content">
      <h1>Thank You</h1>
      <p  style={{textAlign:"center"}}>Thanks for contacting us, we will get in touch with you soon... </p>
         <div style={{margin:"auto",display: "flex",justifyContent:"center",marginTop:"30px"}}> <a className='btn-buy-thakyou'  href="/">Back to home</a></div>
    </div>
  </div>
</div>


    </div>
  )
}

export default Thankyou
