import React from 'react'

const Footer = () => {
  return (
    <div>
      <>
  {/* ======= Footer ======= */}
  <footer id="footer">
    <div className="footer-top">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="footer-info">
              <h3>Contact us</h3>
              <p>
                226, Gufa Mandir Road, Jain Nagar, Nayapura, Lalghati,
                <br />
                Bhopal 462001, Madhya Pradesh
                
                <br />

              </p>
              {/* <p>Phone: +91 8962765627 </p> */}
              <br />
              <p>Email: mwtech@mushroomworldbpl.com </p>
              <br />
              <p />
              <div className="social-links mt-3 ">
                {/* <a href="#" className="twitter">
                  <i className="bx bxl-twitter" />
                </a> */}
                <a href="#" className="facebook">
                <i className="bi bi-facebook" />
                </a>
                <a href="#" className="instagram">
                <i className="bi bi-instagram" />
                </a>
                {/* <a href="#" className="google-plus">
                  <i className="bx bxl-skype" />
                </a> */}
                <a href="#" className="linkedin">
                <i className="bi bi-linkedin" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 footer-links">
            <h4>Useful Links</h4>
            <ul>
              <li>
                <i className="bx bx-chevron-right" /> <a href="#">Home</a>
              </li>
              <li>
                <i className="bx bx-chevron-right" /> <a href="#about">About us</a>
              </li>
              <li>
                <i className="bx bx-chevron-right" /> <a href="#pricing">Services</a>
              </li>
              {/* <li>
                <i className="bx bx-chevron-right" />{" "}
                <a href="#">Terms of service</a>
              </li>
              <li>
                <i className="bx bx-chevron-right" />{" "}
                <a href="#">Privacy policy</a>
              </li> */}
            </ul>
          </div>
          <div className="col-lg-2 col-md-6 footer-links">
            <h4>Our Offerings</h4>
            <ul>
              <li>
                <i className="bx bx-chevron-right" /> <a href="#solo">Website Design <br /> and Development</a>
              </li>
              {/* <li>
                <i className="bx bx-chevron-right" />{" "}
                <a href="#">Web Development</a>
              </li> */}
              <li>
                <i className="bx bx-chevron-right" />{" "}
                <a href="#solo">Product Management <br /> and Advertising</a>
              </li>
              {/* <li>
                <i className="bx bx-chevron-right" /> <a href="#">Marketing</a>
              </li> */}
              <li>
                <i className="bx bx-chevron-right" />{" "}
                <a href="#solo">Design Services <br/> (Logo,Visiting&nbsp;Cards,<br />Letterhead)</a>
              </li>
            </ul>
          </div>
          <div className="col-lg-4 col-md-6 footer-newsletter">
            <h4>Uppthrive</h4>
            <p style={{ fontSize: "small" }}>
              We offer full fledged solutions for
              Entrepreneurs using different plans which includes services like
              logo creation, website creation, domain name , graphic design etc
            </p>
            <form action="" method="post">
              <input type="email" name="email" />
              <input type="submit" defaultValue="Subscribe" />
            </form>
          </div>
        </div>
      </div>
    </div>
  </footer>
  {/* End Footer */}
</>

    </div>
  )
}

export default Footer
