import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Testimomialtwo = () => {
  let data = [
    {
      id: 1,
      names: "Pinky",
      title: "Mushroom World Umbrella",
      country: "Project Strategist & Planning Head",
      description:
        "Their team demonstrated a deep understanding of my vision and translated it into a stunning website and mobile app that exceeded my expectations.",
      imageUrl: "/assets/images/Pinky-Maamm.jpg",
    },
    {
      id: 2,
      names: "Pragya",
      title: "Mushroom World Films",
      country: "Public Relations Manager",
      description:
        "The website they created is not only visually stunning but also highly functional and user-friendly. It perfectly represents my business and has received rave reviews from my customers.",
      imageUrl:
        "/assets/images/pragya.jpg",
    },
    // {
    //   id: 3,
    //   names: "Uthkarsh",
    //   title: "DTPS",
    //   country: "Illustration Artist",
    //   description:
    //     "The team took the time to understand my brand and delivered a logo that perfectly captures its essence. The attention to detail, creativity, and professionalism exhibited by the team were outstanding.",
    //   imageUrl:
    //     "https://mushroomworldfilms.com/wp-content/uploads/2022/10/Prateek-Sir.jpg",
    // },
    {
      id: 4,
      names: "Pratheek",
      title: "Illuminate Gummies",
      country: "Chief Marketing Strategist",
      description:
        "The team has provided invaluable business strategies that have transformed my company. Their expertise and insights have guided me towards success, and their tailored approach has delivered tangible results.",
      imageUrl:
        "/assets/images/Prateek-Sir.jpg",
    },
    {
      id: 5,
      names: "Bharath",
      title: "Khojee",
      country: "Head of Digital Marketing",
      description:
        "Their talented team delivered a logo that exceeded my expectations, capturing the essence of my brand in a visually striking and memorable way. The attention to detail and creativity demonstrated throughout the process truly showcased their expertise.",
      imageUrl: "/assets/images/BharatSir.jpg",
    },
  ];

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 500 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 500, min: 0 },
      items: 1,
    },
  };
  return (
    <div style={{ marginTop: "70px" }}>
      <div className="testimonials">
        <div class="section-title ">
          <h2>Testimonials</h2>
        </div>
        {/* <h1>Testimonials</h1> */}
        <Carousel responsive={responsive} autoPlay={true}>
          {data.map((testimonial) => (
            <div className="testimonial" key={testimonial.id}>
              <img src={testimonial.imageUrl} alt={testimonial.names}></img>
              <div className="personal-info">
                <h1 className="names">{testimonial.names}</h1>
                <h5 className="title">{testimonial.title}</h5>
                <p className="country">{testimonial.country}</p>
              </div>
              <p className="description">{testimonial.description}</p>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default Testimomialtwo;
