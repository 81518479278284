import React, { useRef, useState } from 'react'

const Video = ({url}) => {
    const [isVideoPlaying, setVideoPlaying] = useState(false);
    const videoRef = useRef(null);
    const [isElementVisible, setElementVisibility] = useState(false);



    const handleVideoPlay = () => {
      setElementVisibility(true);
    };
    const handleVideoPause = () => {
      setElementVisibility(false);
    };
  
    const handleDivClick = () => {
        if (isVideoPlaying) {
          videoRef.current.pause();

        } else {
          videoRef.current.play();

        }
        setVideoPlaying(!isVideoPlaying);
      };
  return (
  
  <div className="col-lg-6 order-1 order-lg-2 align-items-stretch video-box"  data-aos="zoom-in">
            {/* <a href="https://www.youtube.com/watch?v=LIqQNG_q2us" class="venobox play-btn mb-4" data-vbtype="video" data-autoplay="true"></a> */}
           
            {isElementVisible ?"" : <div className='play-btn' onClick={handleDivClick}></div> }
 <video style={{aspectRatio:"16/9"}} ref={videoRef}   onPlay={handleVideoPlay} onPause={handleVideoPause}   width="626" height="390" controls >
  <source src={url}/>
</video>
   
          </div>
  
  )
}

export default Video
