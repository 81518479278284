import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Modal, Button, Form, Input } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const Price = () => {
  const [showModal, setShowModal] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [requirements, setRequirements] = useState("");

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default behavior (moving to the next input)
      setRequirements(requirements + "\n"); // Add a line break to the input value
    }
  };
  const handleModalHover = (hovering) => {
    if (hovering) {
      document.querySelector(".modal").classList.add("no-fade");
    } else {
      document.querySelector(".modal").classList.remove("no-fade");
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    fetch("https://formsubmit.co/ajax/mwtech@mushroomworldbpl.com", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        firstname: firstName,
        contact: contactNumber,
        emaill: email,
        address: address,
        requirements: requirements,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        alert("Form submitted");

        setFirstName("");
        setContactNumber("");
        setEmail("");
        setAddress("");
        setRequirements("");
        // e.target[0].value = ""
        // e.target[1].value = ""
        // e.target[2].value = ""
        // e.target[3].value = ""
      })
      .catch((error) => console.log(error));

    console.log({
      firstName,
      contactNumber,
      address,
      requirements,
    });

    // Close the modal
    setShowModal(false);
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Please provide some basic requirements as we will mail and reach out to
      you for detail specifications.
    </Tooltip>
  );

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay() {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const result = await axios.post("http://localhost:5000/payment/orders");

    if (!result) {
      alert("Server error. Are you online?");
      return;
    }

    const { amount, id: order_id, currency } = result.data;

    const options = {
      key: "rzp_test_67mgHdmtPE21lr", // Enter the Key ID generated from the Dashboard
      amount: amount.toString(),
      currency: currency,
      name: "Mushroom World Future Tech.",
      description: "Test Transaction",
      order_id: order_id,
      handler: async function (response) {
        const data = {
          orderCreationId: order_id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
        };

        const result = await axios.post(
          "http://localhost:5000/payment/success",
          data
        );

        alert(result.data.msg);
      },
      prefill: {
        name: "Manish Reddy",
        email: "manishreddy621@example.com",
        contact: "+91 9642698321",
      },
      notes: {
        address: "Example Corporate Office",
      },
      theme: {
        color: "#61dafb",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }
  //   const [data, setData]=useState([])
  //   const getData=()=>{
  //     axios.get('http://localhost:4000/posts').then((res)=>{
  //         setData(res.data)

  //     })
  // }

  // useEffect(()=>{
  //     getData();
  // },[])

  // const carditem=(item)=>{
  //   return(

  //       <div className="box featured" style={{width:" 21rem"}} >
  //     <h3></h3>
  //     <h4>
  //       <sup>$</sup>12<span> / month</span>
  //     </h4>
  //     <ul>
  //       <li>Aida dere</li>
  //       <li>Nec feugiat nisl</li>
  //       <li>Nulla at volutpat dola</li>
  //       <li>Pharetra massa</li>
  //       <li className="na">Massa ultricies mi</li>
  //     </ul>
  //     <div className="btn-wrap">
  //     <Link to={`/product/${item.id}`} className="btn-buy">Buy Now</Link>

  //     </div>
  //   </div>

  //   )
  // }
  return (
    <div>
      <>
        {/* ======= Pricing Section ======= */}

        <section id="pricing" className="pricing">
          <div className="container ">
            <div className="section-title ">
              <h2>Pricing</h2>
            </div>

            {/* <div className='d-flex' style={{justifyContent:"space-around"}}>{data.map(carditem)}</div> */}
            <div
              id="pricegrid"
              className="d-flex"
              style={{ justifyContent: "space-around" }}
            >
              <div
                id="pricing_box"
                className="box featured"
                style={{ width: " 21rem" }}
              >
                <h3>Basic</h3>
                <h4>
                  <sup>₹</sup>4999<span>  Onwards</span>
                </h4>
                <ul>
                  <li>Website Design</li>
                  <li>Logo Design</li>
                  <li className="na">Letter Head Design</li>
                  <li className="na">Visiting Card Design</li>
                  <li className="na">Monthly Maintenance</li>
                  <li className="na">Dedicated Service Assistance</li>
                  <li className="na">Premium E-commerce Development</li>
                </ul>
                <div className="btn-wrap">
                  {/* <button onClick={()=>displayRazorpay()} className="btn-buy">Buy Now</button> */}
                  <div>
                    <button
                      className="btn-buy"
                      onClick={() => setShowModal(true)}
                    >
                      Enquire Now
                    </button>

                    <Modal
                      show={showModal}
                      onHide={() => setShowModal(false)}
                      onMouseEnter={() => handleModalHover(true)}
                      onMouseLeave={() => handleModalHover(false)}
                    >
                      <Modal.Header closeButton>
                        <h1 className="modal-title fs-5 text-center">
                          Contact us
                        </h1>
                      </Modal.Header>
                      <Modal.Body>
                        <Form onSubmit={handleSubmit}>
                          <Form.Group controlId="firstName">
                            <Form.Label>First Name:</Form.Label>
                            <Form.Control
                              required
                              type="text"
                              value={firstName}
                              onChange={(e) => setFirstName(e.target.value)}
                            />
                          </Form.Group>
                          <Form.Group controlId="contactNumber">
  <Form.Label>Contact Number:</Form.Label>
  <Form.Control
    required
    type="number"
    value={contactNumber}
    onChange={(e) => {
      // Use regular expression to remove non-numeric characters
      const numericValue = e.target.value.replace(/\D/g, '');

      // Limit the input to 10 characters
      const limitedValue = numericValue.slice(0, 10);

      setContactNumber(limitedValue);
    }}
  />
</Form.Group>
                          <Form.Group controlId="email">
                            <Form.Label>Email:</Form.Label>
                            <Form.Control
                              required
                              type="email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </Form.Group>
                          <Form.Group controlId="address">
                            <Form.Label>Address:</Form.Label>
                            <Form.Control
                              required
                              type="text"
                              value={address}
                              onChange={(e) => setAddress(e.target.value)}
                            />
                          </Form.Group>
                          <Form.Group controlId="requirements">
                            <Form.Label>Requirements:</Form.Label>{" "}
                            <OverlayTrigger
                              placement="right"
                              delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltip}
                            >
                              <i
                                style={{ fontSize: "25px" }}
                                class="bi bi-info"
                              ></i>
                            </OverlayTrigger>{" "}
                            <Form.Control
                              as="textarea" // Use 'as="textarea"' to allow multiline input
                              required
                              value={requirements}
                              onChange={(e) => setRequirements(e.target.value)}
                              onKeyDown={handleKeyPress} // Handle "Enter" key press
                              style={{
                                fontSize: "18px",
                                height: "120px", // You can adjust the height for a larger input area
                                padding: "10px",
                              }}
                            />
                          </Form.Group>
                          <Button
                            className="mt-3 d-flex mx-auto"
                            variant="primary"
                            type="submit"
                          >
                            Send
                          </Button>
                        </Form>
                      </Modal.Body>

                      <input
                        type="hidden"
                        name="_template"
                        value="table"
                      ></input>
                    </Modal>
                  </div>
                </div>
              </div>

              <div
                id="pricing_box"
                className="box featured"
                style={{ width: " 21rem" }}
              >
                {/* <div class="cr cr-top cr-left cr-sticky cr-red">Hello</div> */}
                <h3>Standard</h3>
                <h4>
                  <sup>₹</sup>6999<span>  Onwards</span>
                </h4>
                <ul>
                  <li>Website Design</li>
                  <li>Logo Design</li>
                  <li>Letter Head Design</li>
                  <li>Visiting Card Design</li>
                  <li className="na">Monthly Maintenance</li>
                  <li className="na">Dedicated Service Assistance</li>
                  <li className="na"> Premium E-commerce Development</li>
                </ul>
                <div className="btn-wrap">
                  <button
                    onClick={() => setShowModal(true)}
                    className="btn-buy"
                  >
                    Enquire Now
                  </button>
                </div>
              </div>
              <div
                id="pricing_box"
                className="box featured"
                style={{ width: " 21rem" }}
              >
                <h3>Premium</h3>
                <h4>
                  <sup>₹</sup>8999<span>  Onwards</span>
                </h4>
                <ul>
                  <li>Website Design</li>
                  <li>Logo Design</li>
                  <li>Letter Head Design</li>
                  <li>Visiting Card Design</li>
                  <li>Monthly Maintenance</li>
                  <li>Dedicated Service Assistance</li>
                  <li>Premium E-commerce Development</li>
                </ul>
                <div className="btn-wrap">
                  <button
                    id="solo"
                    onClick={() => setShowModal(true)}
                    className="btn-buy"
                  >
                    Enquire Now
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="w-100 d-flex mt-5">
            <button
              className="pricing btn-buy mx-auto"
              style={{ border: "none" }}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseExample"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              Know More
            </button>
          </div> */}
          {/* <div className="collapse" id="collapseExample"> */}
          <div>
            {/* ======= solo services pricing Section ======= */}
            {/* <section id="pricing" className="our_pricing">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Solo Services Offered</h2>
          </div>
          <div className="row gy-4" data-aos="fade-left">
            <div
              className="col-lg-3 col-md-6 "
              data-aos="zoom-in"
              data-aos-delay={100}
            >
     <div class="flip-card" tabIndex="0">
  <div class="flip-card-inner">
    <div class="flip-card-front">
    <div className="box">
                <h3 style={{ color: "#CF1919" }}>Logos</h3>
                <div className="price">
                  <sup>₹</sup>999<span>  Onwards</span>
                </div>
                <img
                  src="assets/img/pricing-free.png"
                  className="img-fluid"
                  alt=""
                />
                <ul>
                  <li>Unique Design</li>
                  <li>Multiple Options</li>
                  <li>Affordable Price</li>
                  <li >High Quality File Formats</li>
                </ul>
                <a href="#" className="btn-buy">
                  Buy Now
                </a>
              </div>
    </div>
    <div class="flip-card-back">
    <div className="box">
                <h3 style={{ color: "#CF1919" }}>Logos</h3>
             
                <img
                  src="assets/img/pricing-free.png"
                  className="img-fluid"
                  alt=""
                />
                <ul>
                  <li>Aida dere</li>
                  <li>Nec feugiat nisl</li>
                  <li>Aida dere</li>
                  <li>Nec feugiat nisl</li>
                  <li>Nulla at volutpat dola</li>
                  <li className="na">Pharetra massa</li>
               
                </ul>
           
              </div>
    </div>
  </div>
</div>
            </div>
            <div
              className="col-lg-3 col-md-6"
              data-aos="zoom-in"
              data-aos-delay={200}
            >
            <div class="flip-card" tabIndex="0">
  <div class="flip-card-inner">
    <div class="flip-card-front">
    <div className="box">
               
                <h3 style={{ color: "#CF1919" }}>Website</h3>
                <div className="price">
                  <sup>₹</sup>3499<span>  Onwards</span>
                </div>
                <img
                  src="assets/img/pricing-starter.png"
                  className="img-fluid"
                  alt=""
                />
                <ul>
                  <li>Custom Web Design</li>
                  <li>Quick Responsive  Websites</li>
                  <li>User Friendly Interface</li>
                  <li>SEO Friendly Development</li>
                 
                </ul>
                <a href="#" className="btn-buy">
                  Buy Now
                </a>
              </div>
    </div>
    <div class="flip-card-back">
    <div className="box">
              
                <h3 style={{ color: "#CF1919" }}>Website</h3>
        
                <img
                  src="assets/img/pricing-starter.png"
                  className="img-fluid"
                  alt=""
                />
                <ul>
                  <li>Aida dere</li>
                  <li>Nec feugiat nisl</li>
                  <li>Aida dere</li>
                  <li>Nec feugiat nisl</li>
                  <li>Nulla at volutpat dola</li>
                  <li>Pharetra massa</li>
                 
                </ul>
             
              </div>
    </div>
  </div>
</div>
            </div>
            <div
              className="col-lg-3 col-md-6"
              data-aos="zoom-in"
              data-aos-delay={300}
            >
             <div class="flip-card" tabIndex="0">
  <div class="flip-card-inner">
    <div class="flip-card-front">
    <div className="box">
                <h3 style={{ color: "#CF1919" }}>Letter Head Design</h3>
                <div className="price">
                  <sup>₹</sup>999<span>  Onwards</span>
                </div>
                <img
                  src="assets/img/pricing-business.png"
                  className="img-fluid"
                  alt=""
                />
                <ul>
                  <li>Custom Letter Head Design</li>
                  <li>Customaization Options</li>
                  <li>Print Ready Files</li>
                  <li>Professional Typography</li>
              
                </ul>
                <a href="#" className="btn-buy">
                  Buy Now
                </a>
              </div>
    </div>
    <div class="flip-card-back">
    <div className="box">
                <h3 style={{ color: "#CF1919" }}>Domain</h3>
            
                <img
                  src="assets/img/pricing-business.png"
                  className="img-fluid"
                  alt=""
                />
                <ul>
                  <li>Aida dere</li>
                  <li>Nec feugiat nisl</li>
                  <li>Aida dere</li>
                  <li>Nec feugiat nisl</li>
                  <li>Nulla at volutpat dola</li>
                  <li>Pharetra massa</li>
            
                </ul>
             
              </div>
    </div>
  </div>
</div>
            </div>
            <div
              className="col-lg-3 col-md-6"
              data-aos="zoom-in"
              data-aos-delay={400}
            >
          <div class="flip-card" tabIndex="0">
  <div class="flip-card-inner">
    <div class="flip-card-front">
    <div className="box">
                <h3 style={{ color: "#CF1919" }}>Visiting Card Design</h3>
                <div className="price">
                  <sup>₹</sup>499<span>  Onwards</span>
                </div>
                <img
                  src="assets/img/pricing-ultimate.png"
                  className="img-fluid"
                  alt=""
                />
                <ul>
                  <li>Digital Formats</li>
                  <li>Customization  Options</li>
                  <li>Logo Integration</li>
                  <li>High Quality Printing</li>
                </ul>
                <a href="#" className="btn-buy">
                  Buy Now
                </a>
              </div>
    </div>
    <div class="flip-card-back">
    <div className="box">
                <h3 style={{ color: "#CF1919" }}>Graphic Design</h3>
              
                <img
                  src="assets/img/pricing-ultimate.png"
                  className="img-fluid"
                  alt=""
                />
                <ul>
                  <li>Aida dere</li>
                  <li>Nec feugiat nisl</li>
                  <li>Aida dere</li>
                  <li>Nec feugiat nisl</li>
                  <li>Nulla at volutpat dola</li>
                  <li>Pharetra massa</li>
                 
                </ul>
      
              </div>
    </div>
  </div>
</div>
            </div>
          </div>
        </div>
      </section> */}
            {/* End solo services pricing  Section */}

            {/* without flip */}

            <section id="pricing" className="our_pricing">
              <div className="container" data-aos="fade-up">
                <div className="section-title">
                  <h2>Solo Services Offered</h2>
                </div>
                <div id="Solo-grid" className="row gy-5" data-aos="fade-left">
                  <div
                    className="col-lg col-md-6"
                    data-aos="zoom-in"
                    data-aos-delay={400}
                  >
                    <div class="flip-card" tabIndex="0">
                      <div class="flip-card-inner">
                        <div class="flip-card-front">
                          <div className="box">
                            <h3 style={{ color: "#CF1919" }}>
                              Visiting Card Design
                            </h3>
                            <div className="price">
                              <sup>₹</sup>499<span>  Onwards</span>
                            </div>
                            <img
                              src="assets/img/pricing-ultimate.png"
                              className="img-fluid"
                              alt=""
                            />
                            <ul>
                              <li>Digital&nbsp;Formats</li>
                              <li>Customization&nbsp;Options</li>
                              <li>Logo&nbsp;Integration</li>
                              <li>High&nbsp;Quality&nbsp;Printing</li>
                            </ul>
                            {/* <a href="#" className="btn-buy">
                              Enquire Now
                            </a> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg col-md-6"
                    data-aos="zoom-in"
                    data-aos-delay={300}
                  >
                    <div class="flip-card" tabIndex="0">
                      <div class="flip-card-inner">
                        <div class="flip-card-front">
                          <div className="box">
                            <h3 style={{ color: "#CF1919" }}>
                              Letter Head Design
                            </h3>
                            <div className="price">
                              <sup>₹</sup>999<span>  Onwards</span>
                            </div>
                            <img
                              src="assets/img/pricing-business.png"
                              className="img-fluid"
                              alt=""
                            />
                            <ul>
                              <li style={{ fontSize: "15px" }}>
                                Custom&nbsp;Letter&nbsp;Head&nbsp;Design
                              </li>
                              <li>Customaization&nbsp;Options</li>
                              <li>Print&nbsp;Ready&nbsp;Files</li>
                              <li>Professional&nbsp;Typography</li>
                            </ul>
                            {/* <a href="#" className="btn-buy">
                              Enquire Now
                            </a> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg col-md-6"
                    data-aos="zoom-in"
                    data-aos-delay={100}
                  >
                    <div class="flip-card" tabIndex="0">
                      <div class="flip-card-inner">
                        <div class="flip-card-front">
                          <div className="box">
                            <h3 style={{ color: "#CF1919" }}>Logos</h3>
                            <div className="price">
                              <sup>₹</sup>999<span>  Onwards</span>
                            </div>
                            <img
                              src="assets/img/pricing-free.png"
                              className="img-fluid"
                              alt=""
                            />
                            <ul>
                              <li>Unique&nbsp;Design</li>
                              <li>Multiple&nbsp;Options</li>
                              <li>Affordable&nbsp;Price</li>
                              <li>High&nbsp;Quality&nbsp;File&nbsp;Formats</li>
                            </ul>
                            {/* <a href="#" className="btn-buy">
                              Enquire Now
                            </a> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg col-md-6"
                    data-aos="zoom-in"
                    data-aos-delay={200}
                  >
                    <div class="flip-card" tabIndex="0">
                      <div class="flip-card-inner">
                        <div class="flip-card-front">
                          <div className="box">
                            <h3 style={{ color: "#CF1919" }}>Website</h3>
                            <div className="price">
                              <sup>₹</sup>3499<span>  Onwards</span>
                            </div>
                            <img
                              src="assets/img/pricing-starter.png"
                              className="img-fluid"
                              alt=""
                            />
                            <ul>
                              <li>Custom&nbsp;Web&nbsp;Design</li>
                              <li>Responsive&nbsp;Websites</li>
                              <li>User&nbsp;Friendly&nbsp;Interface</li>
                              <li>SEO&nbsp;Friendly&nbsp;Development</li>
                            </ul>
                            {/* <a href="#" className="btn-buy">
                              Enquire Now
                            </a> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg col-md-6"
                    data-aos="zoom-in"
                    data-aos-delay={400}
                  >
                    <div class="flip-card" tabIndex="0">
                      <div class="flip-card-inner">
                        <div class="flip-card-front">
                          <div className="box">
                            <h3 style={{ color: "#CF1919" }}>
                              Product&nbsp;Management
                            </h3>
                            <div className="price">
                              <sup>₹</sup>499<span>  Onwards</span>
                            </div>
                            <img
                              src="assets/img/pricing-ultimate.png"
                              className="img-fluid"
                              alt=""
                            />
                            <ul>
                              <li>Product&nbsp;Growth</li>
                              <li>Digital&nbsp;Marketing</li>
                              <li>Product&nbsp;Photo&nbsp;Shoots</li>
                              <li>Product&nbsp;Sales&nbsp;Plan</li>
                            </ul>
                            {/* <a href="#" id="clients" className="btn-buy">
                              Enquire Now
                            </a> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
        {/* End Pricing Section */}
      </>
    </div>
  );
};

export default Price;
