import React, { useState } from 'react'

const Contact = () => {
  const [loading, setLoading] = useState(false)
  const FormSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    fetch("https://formsubmit.co/ajax/c8e9299aec25f7718fe23637b2ee6f06 ", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        name: e?.target[0]?.value,
        email: e?.target[1]?.value,
        subject: e?.target[2]?.value,
        message: e?.target[3]?.value
      })
    })
      .then(response => response.json())
      .then(data => {
        alert("Contact submitted")
        setLoading(false)
        e.target[0].value = ""
        e.target[1].value = ""
        e.target[2].value = ""
        e.target[3].value = ""
      })
      .catch(error => console.log(error));
  }
  return (
    <div>
        
    <section id="contact" class="contact">
      <div class="container">

        <div class="section-title">
          <h2>Contact</h2>
        
        </div>

        <div>
          <iframe style={{border:"0" ,width: "100%"  ,height: "270px"}} src="https://maps.google.com/maps?q=226, JAIN NAGAR  GUFA MANDIR ROAD, BHOPAL  MADHYA PRADESH 462030&t=&z=10&ie=UTF8&iwloc=&output=embed" frameborder="0" allowfullscreen></iframe>
        </div>

        <div class="row mt-5">

          <div class="col-lg-4">
            <div class="info">
              <div class="address">
                <i class="bi bi-geo-alt"></i>
                <h4>Location:</h4>
                <p>226, Gufa Mandir Road, Jain Nagar, Nayapura, Lalghati,
                  Bhopal 462001, Madhya Pradesh
                  <br />
                 
                <br /></p>
                  
              </div>

              <div class="email">
                <i class="bi bi-envelope"></i>
                <h4>Email:</h4>
                <p>mwtech@mushroomworldbpl.com</p>
              </div>

              {/* <div class="phone">
                <i class="bi bi-phone"></i>
                <h4>Call:</h4>
                <p>+91 8962765627</p>
              </div> */}

            </div>

          </div>

          <div class="col-lg-8 mt-5 mt-lg-0">

            <form  action="https://formsubmit.co/c8e9299aec25f7718fe23637b2ee6f06" method="POST" role="form" class="php-email-form">
              
              <div class="row">
                <div class="col-md-6 form-group">
                  <input type="text" name="name" class="form-control" id="name" placeholder="Your Name" required/>
                </div>
                <div class="col-md-6 form-group mt-3 mt-md-0">
                  <input type="email" class="form-control" name="email" id="email" placeholder="Your Email" required/>
                </div>
              </div>
              <div class="form-group mt-3">
                <input type="text" class="form-control" name="subject" id="subject" placeholder="Subject" required/>
              </div>
              <div class="form-group mt-3">
                <textarea class="form-control" name="message" rows="5" placeholder="Message" required></textarea>
              </div>
              <div class="my-3">
                <div class="loading">Loading</div>
                <div class="error-message"></div>
                <div class="sent-message">Your message has been sent. Thank you!</div>
              </div>
              <input type="hidden" name="_template" value="table"/>
              <input type="hidden" name="_captcha" value="false"></input>
              <input type="hidden" name="_next" value="http://localhost:3000/Thankyou"></input>
              <div class="text-center"><button type="submit">Send Message</button></div>
          
            </form>

          </div>

        </div>

      </div>
    </section>
    </div>
  )
}

export default Contact
