import React, { useRef, useState } from "react";
import { IoCloseOutline } from "react-icons/io5";
import { BiLoaderAlt } from "react-icons/bi";
import Video from "./video";

const Viedio = () => {
  const [modal, setModal] = useState(false);
  const [videoLoading, setVideoLoading] = useState(true);

  const [currentTab, setCurrentTab] = useState(1);

  const openModal = () => {
    setModal(!modal);
  };

  const spinner = () => {
    setVideoLoading(!videoLoading);
  };
  return (
    <div>
      <>
        <section id="features" className="features why-us mt-5">
          <div
            className="container"
            style={{ marginTop: "30px" }}
            data-aos="fade-up"
          >
            <div className="section-title">
              <h2>What people feel about us</h2>
            </div>
            <ul className="nav nav-tabs row d-flex">
              <li className="nav-item col-3" onClick={()=>setCurrentTab(1)}>
                <a
                  className="nav-link active show"
                  data-bs-toggle="tab"
                  href="#tab-1"
                >
                  <img
                    src="https://iili.io/HrPckmP.png"
                    width="25%"
                    height="100%"
                    alt=""
                  />
                  {/* <i className="ri-gps-line" /> */}
                  <h4 className="d-none d-lg-block">
                    {" "}
                    &nbsp;&nbsp;TEFUN GAME{" "}
                  </h4>
                </a>
              </li>
              <li className="nav-item col-3" onClick={()=>setCurrentTab(2)}>
                <a className="nav-link" data-bs-toggle="tab" href="#tab-2">
                  <img
                    src="https://iili.io/HrvuxYQ.png"
                    width="25%"
                    height="100%"
                    alt=""
                  />
                  {/* <i className="ri-body-scan-line" /> */}
                  <h4 className="d-none d-lg-block">&nbsp;&nbsp;KHOJEE </h4>
                </a>
              </li>
              <li className="nav-item col-3" onClick={()=>setCurrentTab(3)}>
                <a className="nav-link" data-bs-toggle="tab" href="#tab-3">
                  <img
                    src="https://iili.io/HrPliMu.png"
                    width="25%"
                    height="100%"
                    alt=""
                  />
                  {/* <i className="ri-sun-line" /> */}
                  <h4 className="d-none d-lg-block">&nbsp;&nbsp;Kartavyam </h4>
                </a>
              </li>
              <li className="nav-item col-3" onClick={()=>setCurrentTab(4)}>
                <a className="nav-link" data-bs-toggle="tab" href="#tab-4">
                  {/* <i className="ri-store-line" /> */}
                  <img
                    src="https://iili.io/HrP0KSR.png"
                    width="25%"
                    height="100%"
                    alt=""
                  />
                  <h4 className="d-none d-lg-block">&nbsp;&nbsp;Mushroomex</h4>
                </a>
              </li>
            </ul>
            <div  className="tab-content">
              {currentTab === 1 ? (
                <div className="tab-pane active show" id="tab-1">
                  <div className="row">
                    <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                      <h3>
                        In our studio, we create compelling advertisements for
                        our clients that effectively convey their brand messages
                        and drive desired outcomes.
                      </h3>
                      <p className="fst-italic">
                        Through a collaborative process, we work closely with
                        our clients to understand their goals, target audience,
                        and unique selling points.
                      </p>
                      <ul>
                        <li>
                          <i className="ri-check-double-line" /> Armed with this
                          knowledge, we craft engaging and persuasive messages
                          that resonate with their intended audience.
                        </li>

                        <li>
                          <i className="ri-check-double-line" /> Our team of
                          talented designers and marketers then bring these
                          ideas to life, creating visually stunning and
                          impactful visuals that capture attention and leave a
                          lasting impression.
                        </li>
                      </ul>
                      <p>
                        With a keen eye for detail and a deep understanding of
                        various advertising channels, we strategically select
                        the right platforms to showcase these ads, ensuring
                        maximum reach and impact. Our ultimate aim is to help
                        our clients achieve their advertising objectives and
                        drive meaningful results for their businesses.
                      </p>
                    </div>
                    <Video
                      url={
                        "https://firebasestorage.googleapis.com/v0/b/file-2e172.appspot.com/o/TEFUN%20_%20Strategic%20Battle%20Game%20_%20Get%20Ready%20For%20_TEFUN%20THE%20GREAT%20TOURNAMENT_.mp4?alt=media&token=1c6dae52-3f4e-4465-9f44-1fd29e97f819&_gl=1*4w3k45*_ga*MTI2NzMxNTIwMC4xNjg0ODMwMjE4*_ga_CW55HF8NVT*MTY4NTY5OTY3NS4xMS4xLjE2ODU2OTk2OTQuMC4wLjA."
                      }
                    />
                    {/* <div className="col-lg-6 order-1 order-lg-2 align-items-stretch video-box"  data-aos="zoom-in">
        
           
            {isElementVisible ?"" : <div className='play-btn' onClick={handleDivClick}></div> }
 <video style={{aspectRatio:"16/9"}} ref={videoRef}   onPlay={handleVideoPlay} onPause={handleVideoPause}   width="560" height="390" controls >
  <source src="https://firebasestorage.googleapis.com/v0/b/file-2e172.appspot.com/o/TEFUN%20_%20Strategic%20Battle%20Game%20_%20Get%20Ready%20For%20_TEFUN%20THE%20GREAT%20TOURNAMENT_.mp4?alt=media&token=1c6dae52-3f4e-4465-9f44-1fd29e97f819&_gl=1*4w3k45*_ga*MTI2NzMxNTIwMC4xNjg0ODMwMjE4*_ga_CW55HF8NVT*MTY4NTY5OTY3NS4xMS4xLjE2ODU2OTk2OTQuMC4wLjA."/>
</video>
   
          </div> */}
                  </div>
                </div>
              ) : (
                ""
              )}
              {currentTab === 2 ? (
                <div className="tab-pane active show" id="tab-2">
                  <div className="row">
                    <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                      <h3>
                        In our studio,We create best in class video ads and
                        marketing techniques.
                      </h3>
                      <p className="fst-italic">
                        Through a collaborative process, we work closely with
                        our clients to understand their goals, target audience,
                        and unique selling points.
                      </p>
                      <ul>
                        <li>
                          <i className="ri-check-double-line" /> With the help
                          of our team's creativity. We provide state of the art
                          videos for business professionals.
                        </li>

                        <li>
                          <i className="ri-check-double-line" /> Our videos meet
                          all the quality standards and are pleasing to the eye
                          giving an upper hand reaching the target audience
                          effectively.
                        </li>
                      </ul>
                      <p>
                        With a keen eye for detail and a deep understanding of
                        various advertising channels, we strategically select
                        the right platforms to showcase these ads, ensuring
                        maximum reach and impact. Our ultimate aim is to help
                        our clients achieve their advertising objectives and
                        drive meaningful results for their businesses.
                      </p>
                    </div>

                    <Video
                      url={
                        "https://firebasestorage.googleapis.com/v0/b/file-2e172.appspot.com/o/KHOJEE-%20The%20Treasure%20Hunt%20_%20BHOPAL%200.1%20_%20Promo.mp4?alt=media&token=51eb153a-c7d8-4a79-9e7a-56ca50cdacc3&_gl=1*vvxaxt*_ga*MTI2NzMxNTIwMC4xNjg0ODMwMjE4*_ga_CW55HF8NVT*MTY4NTY4MzE3OC44LjEuMTY4NTY4MzIzNC4wLjAuMA.."
                      }
                    />
                    {/* <div className="col-lg-6 order-1 order-lg-2 align-items-stretch video-box"   data-aos="zoom-in">
        
            
            {isElementVisible ?"" : <div className='play-btn' onClick={handleDivClick}></div> }
 <video style={{aspectRatio:"16/9"}}  ref={videoRef2} onPlay={handleVideoPlay} onPause={handleVideoPause}   width="560" height="390" controls >
  <source src="https://firebasestorage.googleapis.com/v0/b/file-2e172.appspot.com/o/KHOJEE-%20The%20Treasure%20Hunt%20_%20BHOPAL%200.1%20_%20Promo.mp4?alt=media&token=51eb153a-c7d8-4a79-9e7a-56ca50cdacc3&_gl=1*vvxaxt*_ga*MTI2NzMxNTIwMC4xNjg0ODMwMjE4*_ga_CW55HF8NVT*MTY4NTY4MzE3OC44LjEuMTY4NTY4MzIzNC4wLjAuMA.."/>
</video>
            

          </div> */}
                  </div>
                </div>
              ) : (
                ""
              )}
              {currentTab === 3 ? (
                <div className="tab-pane active show" id="tab-3">
                  <div className="row">
                    <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                      <h3>
                        In our studio, we create compelling advertisements for
                        our clients that effectively convey their brand messages
                        and drive desired outcomes.
                      </h3>
                      <p className="fst-italic">
                        Through a collaborative process, we work closely with
                        our clients to understand their goals, target audience,
                        and unique selling points.
                      </p>
                      <ul>
                        <li>
                          <i className="ri-check-double-line" /> A team of multi
                          talented people that work closely to meet all of the
                          clients's requirements.
                        </li>

                        <li>
                          <i className="ri-check-double-line" /> Our team of
                          talented designers and marketers collaborate to give
                          the state of the art results and bring ideas to life{" "}.
                        </li>
                      </ul>
                      <p>
                        With a keen eye for detail and a deep understanding of
                        various advertising channels, we strategically select
                        the right platforms to showcase these ads, ensuring
                        maximum reach and impact. Our ultimate aim is to help
                        our clients achieve their advertising objectives and
                        drive meaningful results for their businesses.
                      </p>
                    </div>
                    <Video
                      url={
                        "https://firebasestorage.googleapis.com/v0/b/file-2e172.appspot.com/o/Kartavyam%20Nasha%20Mukti%20Kendra%20Bhopal%20_%20All%20Details.mp4?alt=media&token=e59ae7e1-c0e7-4194-b95d-29df014f8c0a&_gl=1*xkdfst*_ga*MTI2NzMxNTIwMC4xNjg0ODMwMjE4*_ga_CW55HF8NVT*MTY4NTYyNTk0Ni42LjEuMTY4NTYyNjAyOS4wLjAuMA.."
                      }
                    />
                    {/* <div className="col-lg-6 order-1 order-lg-2 align-items-stretch video-box "  data-aos="zoom-in">
        
            {isElementVisible ?"" : <div className='play-btn' onClick={handleDivClick}></div> }
 <video style={{aspectRatio:"16/9"}}  onPlay={handleVideoPlay} ref={videoRef3} onPause={handleVideoPause}   width="560" height="390" controls >
  <source src="https://firebasestorage.googleapis.com/v0/b/file-2e172.appspot.com/o/Kartavyam%20Nasha%20Mukti%20Kendra%20Bhopal%20_%20All%20Details.mp4?alt=media&token=e59ae7e1-c0e7-4194-b95d-29df014f8c0a&_gl=1*xkdfst*_ga*MTI2NzMxNTIwMC4xNjg0ODMwMjE4*_ga_CW55HF8NVT*MTY4NTYyNTk0Ni42LjEuMTY4NTYyNjAyOS4wLjAuMA.."/>
</video>
          </div> */}
                  </div>
                </div>
              ) : (
                ""
              )}
              {currentTab === 4 ? (
                <div className="tab-pane active show" id="tab-4">
                  <div className="row">
                    <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                      <h3>All of the startup needs under one roof.</h3>
                      <p className="fst-italic">
                        Through a collaborative process, we work closely with
                        our clients to understand their goals, target audience,
                        and unique selling points.
                      </p>
                      <ul>
                        <li>
                          <i className="ri-check-double-line" /> Armed with this
                          knowledge, we craft engaging and persuasive messages
                          that resonate with their intended audience.
                        </li>

                        <li>
                          <i className="ri-check-double-line" /> Our team of
                          talented designers and marketers then bring these
                          ideas to life, creating visually stunning and
                          impactful visuals that capture attention and leave a
                          lasting impression.
                        </li>
                      </ul>
                      <p>
                        With a keen eye for detail and a deep understanding of
                        various advertising channels, we strategically select
                        the right platforms to showcase these ads, ensuring
                        maximum reach and impact. Our ultimate aim is to help
                        our clients achieve their advertising objectives and
                        drive meaningful results for their businesses.
                      </p>
                    </div>
                    <Video
                      url={
                        "https://firebasestorage.googleapis.com/v0/b/file-2e172.appspot.com/o/Mushroomex%20Mushroom%20Powder%20(Aman%20Verma).mp4?alt=media&token=bae226b9-6dc5-4652-b7df-b8cd7449653e&_gl=1*135pbj7*_ga*MTI2NzMxNTIwMC4xNjg0ODMwMjE4*_ga_CW55HF8NVT*MTY4NTY4MzE3OC44LjEuMTY4NTY4NDY4Mi4wLjAuMA.."
                      }
                    />
                    {/* <div className="col-lg-6 order-1 order-lg-2 align-items-stretch video-box"   data-aos="zoom-in">
          
            {isElementVisible ?"" : <div className='play-btn' onClick={handleDivClick}></div> }
 <video style={{aspectRatio:"16/9"}} ref={videoRef4} onPlay={handleVideoPlay} onPause={handleVideoPause}   width="560" height="390" controls >
  <source src="https://firebasestorage.googleapis.com/v0/b/file-2e172.appspot.com/o/Mushroomex%20Mushroom%20Powder%20(Aman%20Verma).mp4?alt=media&token=bae226b9-6dc5-4652-b7df-b8cd7449653e&_gl=1*135pbj7*_ga*MTI2NzMxNTIwMC4xNjg0ODMwMjE4*_ga_CW55HF8NVT*MTY4NTY4MzE3OC44LjEuMTY4NTY4NDY4Mi4wLjAuMA.."/>
</video>
          </div> */}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </section>
      </>
    </div>
  );
};

export default Viedio;

{
  /* ======= Features Section ======= */
}
//   <section id="features"  className="features why-us mt-5">
//   <div className="container" style={{marginTop:"30px"}} data-aos="fade-up">
//   <div className="section-title">
//       <h2>What people feel about us</h2>
//     </div>
//     <ul className="nav nav-tabs row d-flex">
//       <li className="nav-item col-3">
//         <a
//           className="nav-link active show"
//           data-bs-toggle="tab"
//           href="#tab-1"
//         >
//           <img src="https://iili.io/HrPckmP.png" width="25%" height="100%" alt="" />
//           {/* <i className="ri-gps-line" /> */}
//           <h4 className="d-none d-lg-block"> &nbsp;&nbsp;TEFUN GAME  </h4>
//         </a>
//       </li>
//       <li className="nav-item col-3">
//         <a className="nav-link" data-bs-toggle="tab" href="#tab-2">
//           <img src="https://iili.io/HrvuxYQ.png" width="25%" height="100%" alt="" />
//           {/* <i className="ri-body-scan-line" /> */}
//           <h4 className="d-none d-lg-block">&nbsp;&nbsp;KHOJEE </h4>
//         </a>
//       </li>
//       <li className="nav-item col-3">
//         <a className="nav-link" data-bs-toggle="tab" href="#tab-3">
//         <img src="https://iili.io/HrPliMu.png" width="25%" height="100%" alt="" />
//           {/* <i className="ri-sun-line" /> */}
//           <h4 className="d-none d-lg-block">&nbsp;&nbsp;Kartavyam    </h4>
//         </a>
//       </li>
//       <li className="nav-item col-3">
//         <a className="nav-link" data-bs-toggle="tab" href="#tab-4">
//           {/* <i className="ri-store-line" /> */}
//           <img src="https://iili.io/HrP0KSR.png" width="25%" height="100%" alt="" />
//           <h4 className="d-none d-lg-block">&nbsp;&nbsp;Mushroomex</h4>
//         </a>
//       </li>
//     </ul>
//     <div className="tab-content">
//       <div className="tab-pane active show" id="tab-1">
//         <div className="row">
//           <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
//             <h3>
//             In our studio, we create compelling advertisements for our clients that effectively convey their brand messages and drive desired outcomes.
//             </h3>
//             <p className="fst-italic">
//               Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
//               eiusmod tempor incididunt ut labore et dolore magna aliqua.
//             </p>
//             <ul>
//               <li>
//                 <i className="ri-check-double-line" /> Ullamco laboris nisi ut
//                 aliquip ex ea commodo consequat.
//               </li>
//               <li>
//                 <i className="ri-check-double-line" /> Duis aute irure dolor
//                 in reprehenderit in voluptate velit.
//               </li>
//               <li>
//                 <i className="ri-check-double-line" /> Ullamco laboris nisi ut
//                 aliquip ex ea commodo consequat. Duis aute irure dolor in
//                 reprehenderit in voluptate trideta storacalaperda mastiro
//                 dolore eu fugiat nulla pariatur.
//               </li>
//             </ul>
//             <p>
//               Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis
//               aute irure dolor in reprehenderit in voluptate velit esse cillum
//               dolore eu fugiat nulla pariatur. Excepteur sint occaecat
//               cupidatat non proident, sunt in culpa qui officia deserunt
//               mollit anim id est laborum
//             </p>
//           </div>
//           <div className="col-lg-6 order-1 order-lg-2 align-items-stretch video-box"  data-aos="zoom-in">
//           {/* <a href="https://www.youtube.com/watch?v=LIqQNG_q2us" class="venobox play-btn mb-4" data-vbtype="video" data-autoplay="true"></a> */}

//           {isElementVisible ?"" : <div className='play-btn'>dfs</div> }
// <video style={{aspectRatio:"16/9"}}  onPlay={handleVideoPlay} onPause={handleVideoPause}   width="560" height="390" controls >
// <source src="https://firebasestorage.googleapis.com/v0/b/file-2e172.appspot.com/o/Kartavyam%20Nasha%20Mukti%20Kendra%20Bhopal%20_%20All%20Details.mp4?alt=media&token=e59ae7e1-c0e7-4194-b95d-29df014f8c0a&_gl=1*xkdfst*_ga*MTI2NzMxNTIwMC4xNjg0ODMwMjE4*_ga_CW55HF8NVT*MTY4NTYyNTk0Ni42LjEuMTY4NTYyNjAyOS4wLjAuMA.."/>
// </video>

//                 {/* <iframe
//                   className="modal__video-style "
//                   onLoad={spinner}
//                   loading="lazy"
//                   width="560"
//                   height="430"
//                   src="https://www.youtube.com/embed/Fn4QfZoafMY?modestbranding=0"
//                   title="YouTube video player"
//                   frameBorder="0"
//                   allow="accelerometer; autoplay;"
//                   allowfullscreen
//                 ></iframe> */}

//         </div>
//         </div>
//       </div>
//       <div className="tab-pane" id="tab-2">
//         <div className="row">
//           <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
//             <h3>
//               Neque exercitationem debitis soluta quos debitis quo mollitia
//               officia est
//             </h3>
//             <p>
//               Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis
//               aute irure dolor in reprehenderit in voluptate velit esse cillum
//               dolore eu fugiat nulla pariatur. Excepteur sint occaecat
//               cupidatat non proident, sunt in culpa qui officia deserunt
//               mollit anim id est laborum
//             </p>
//             <p className="fst-italic">
//               Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
//               eiusmod tempor incididunt ut labore et dolore magna aliqua.
//             </p>
//             <ul>
//               <li>
//                 <i className="ri-check-double-line" /> Ullamco laboris nisi ut
//                 aliquip ex ea commodo consequat.
//               </li>
//               <li>
//                 <i className="ri-check-double-line" /> Duis aute irure dolor
//                 in reprehenderit in voluptate velit.
//               </li>
//               <li>
//                 <i className="ri-check-double-line" /> Provident mollitia
//                 neque rerum asperiores dolores quos qui a. Ipsum neque dolor
//                 voluptate nisi sed.
//               </li>

//             </ul>
//           </div>
//           <div className="col-lg-6 order-1 order-lg-2 align-items-stretch video-box"  onClick={openModal} style={{backgroundImage: 'url("https://iili.io/Hr1KlBS.png")'}} data-aos="zoom-in">
//           {/* <a href="https://www.youtube.com/watch?v=LIqQNG_q2us" class="venobox play-btn mb-4" data-vbtype="video" data-autoplay="true"></a> */}
//           <div className='play-btn'>dfs</div>
//           {modal ? (
//         <section className="modal__bg ">
//           <div className="modal__align">
//             <div className="modal__content" modal={modal}>
//               <IoCloseOutline
//                 className="modal__close"
//                 arial-label="Close modal"
//                 onClick={setModal}
//               />
//               <div className="modal__video-align">
//                 {videoLoading ? (
//                   <div className="modal__spinner">
//                     <BiLoaderAlt
//                       className="modal__spinner-style"
//                       fadeIn="none"
//                     />
//                   </div>
//                 ) : null}
//                 <iframe
//                   className="modal__video-style "
//                   onLoad={spinner}
//                   loading="lazy"
//                   width="800"
//                   height="500"
//                   src="https://www.youtube.com/embed/1SlxnldPwnE"
//                   title="YouTube video player"
//                   frameBorder="0"
//                   allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//                   allowfullscreen
//                 ></iframe>
//               </div>
//             </div>
//           </div>
//         </section>
//       ) : null}
//         </div>
//         </div>
//       </div>
//       <div className="tab-pane" id="tab-3">
//         <div className="row">
//           <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
//             <h3>
//               Voluptatibus commodi ut accusamus ea repudiandae ut autem dolor
//               ut assumenda
//             </h3>
//             <p>
//               Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis
//               aute irure dolor in reprehenderit in voluptate velit esse cillum
//               dolore eu fugiat nulla pariatur. Excepteur sint occaecat
//               cupidatat non proident, sunt in culpa qui officia deserunt
//               mollit anim id est laborum
//             </p>
//             <ul>
//               <li>
//                 <i className="ri-check-double-line" /> Ullamco laboris nisi ut
//                 aliquip ex ea commodo consequat.
//               </li>
//               <li>
//                 <i className="ri-check-double-line" /> Duis aute irure dolor
//                 in reprehenderit in voluptate velit.
//               </li>
//               <li>
//                 <i className="ri-check-double-line" /> Provident mollitia
//                 neque rerum asperiores dolores quos qui a. Ipsum neque dolor
//                 voluptate nisi sed.
//               </li>
//             </ul>
//             <p className="fst-italic">
//               Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
//               eiusmod tempor incididunt ut labore et dolore magna aliqua.
//             </p>
//           </div>
//           <div className="col-lg-6 order-1 order-lg-2 align-items-stretch video-box " onClick={openModal} style={{backgroundImage: 'url("https://iili.io/Hr1f367.png")'}} data-aos="zoom-in">
//           {/* <a href="https://www.youtube.com/watch?v=LIqQNG_q2us" class="venobox play-btn mb-4" data-vbtype="video" data-autoplay="true"></a> */}
//           <div className='play-btn'>dfs</div>
//           {modal ? (
//         <section className="modal__bg ">
//           <div className="modal__align">
//             <div className="modal__content" modal={modal}>
//               <IoCloseOutline
//                 className="modal__close"
//                 arial-label="Close modal"
//                 onClick={setModal}
//               />
//               <div className="modal__video-align">
//                 {videoLoading ? (
//                   <div className="modal__spinner">
//                     <BiLoaderAlt
//                       className="modal__spinner-style"
//                       fadeIn="none"
//                     />
//                   </div>
//                 ) : null}
//                 <iframe
//                   className="modal__video-style "
//                   onLoad={spinner}
//                   loading="lazy"
//                   width="800"
//                   height="500"
//                   src="https://www.youtube.com/embed/HvuoDIGNnCM"
//                   title="YouTube video player"
//                   frameBorder="0"
//                   allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//                   allowfullscreen
//                 ></iframe>
//               </div>
//             </div>
//           </div>
//         </section>
//       ) : null}
//         </div>
//         </div>
//       </div>
//       <div className="tab-pane" id="tab-4">
//         <div className="row">
//           <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
//             <h3>
//               Omnis fugiat ea explicabo sunt dolorum asperiores sequi
//               inventore rerum
//             </h3>
//             <p>
//               Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis
//               aute irure dolor in reprehenderit in voluptate velit esse cillum
//               dolore eu fugiat nulla pariatur. Excepteur sint occaecat
//               cupidatat non proident, sunt in culpa qui officia deserunt
//               mollit anim id est laborum
//             </p>
//             <p className="fst-italic">
//               Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
//               eiusmod tempor incididunt ut labore et dolore magna aliqua.
//             </p>
//             <ul>
//               <li>
//                 <i className="ri-check-double-line" /> Ullamco laboris nisi ut
//                 aliquip ex ea commodo consequat.
//               </li>
//               <li>
//                 <i className="ri-check-double-line" /> Duis aute irure dolor
//                 in reprehenderit in voluptate velit.
//               </li>
//               <li>
//                 <i className="ri-check-double-line" /> Ullamco laboris nisi ut
//                 aliquip ex ea commodo consequat. Duis aute irure dolor in
//                 reprehenderit in voluptate trideta storacalaperda mastiro
//                 dolore eu fugiat nulla pariatur.
//               </li>
//             </ul>
//           </div>
//           <div className="col-lg-6 order-1 order-lg-2 align-items-stretch video-box"  onClick={openModal} style={{backgroundImage: 'url("https://iili.io/Hr1fMPI.png")'}} data-aos="zoom-in">
//           {/* <a href="https://www.youtube.com/watch?v=LIqQNG_q2us" class="venobox play-btn mb-4" data-vbtype="video" data-autoplay="true"></a> */}
//           <div className='play-btn'>dfs</div>
//           {modal ? (
//         <section className="modal__bg ">
//           <div className="modal__align">
//             <div className="modal__content" modal={modal}>
//               <IoCloseOutline
//                 className="modal__close"
//                 arial-label="Close modal"
//                 onClick={setModal}
//               />
//               <div className="modal__video-align">
//                 {videoLoading ? (
//                   <div className="modal__spinner">
//                     <BiLoaderAlt
//                       className="modal__spinner-style"
//                       fadeIn="none"
//                     />
//                   </div>
//                 ) : null}
//                 <iframe
//                   className="modal__video-style "
//                   onLoad={spinner}
//                   loading="lazy"
//                   width="800"
//                   height="500"
//                   src="https://www.youtube.com/embed/jBE5BnkIXys"
//                   title="YouTube video player"
//                   frameBorder="0"
//                   allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//                   allowfullscreen
//                 ></iframe>
//               </div>
//             </div>
//           </div>
//         </section>
//       ) : null}
//         </div>
//         </div>
//       </div>
//     </div>
//   </div>
// </section>
{
  /* End Features Section */
}
