import React from 'react'
import Slider from "react-slick";

const Clint = () => {
    const settings = {
        // dots: true,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2700,
        cssEase: "linear",
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
              infinite: true,
          
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
  return (
    

    <div id="clients">
      <div id="testimonials">
      <div className="section-title">
        <h2>Our Partners</h2>
      </div>
       
       <div className='partner-cont'>
        
     
            <div className='CONT'><img src="https://iili.io/21oXaqX.png" style={{objectFit:"contain",objectPosition:"center",paddingTop:"8px"}} height="95%" width="100%"  alt="umbre" /></div>
          
     
        
         
    
      
     
            <div className='CONT'><img src="https://iili.io/21oWAPe.png" style={{objectFit:"contain",objectPosition:"center",paddingTop:"8px"}} height="95%"  width="100%"  alt="filims" /></div>
      
     
     
          <div className='CONT'><img src="https://iili.io/21oWsR4.png" style={{objectFit:"contain",objectPosition:"center",paddingTop:"8px"}} height="95%" width="100%"  alt="future" /></div>
        
       </div>

     
      </div>
    </div>

  )
}

export default Clint

// copy

{/* <div id="clients">
<div>
<div className="section-title">
  <h2>Our Clients</h2>
</div>
  <Slider {...settings}>
    <div>
    <div className='marquecard'>
      <div className='CONT'><img src="https://iili.io/Hrv3Ff9.png" height="100%" width="100%"  alt="future" /></div>
    </div>
    </div>
    <div>
    <div className='marquecard'>
      <div className='CONT'><img src="https://iili.io/Hrv3R0F.png" style={{objectFit:"contain",objectPosition:"center",paddingTop:"8px"}} height="90%" width="100%"   alt="group" /></div>
    </div>
    </div>
    <div>
    <div className='marquecard'>
      <div className='CONT'><img src="https://iili.io/Hrv31xp.png"  height="100%" width="100%"  alt="ayurved" /></div>
    </div>
    </div>
    <div>
    <div className='marquecard'>
      <div className='CONT'><img src="https://iili.io/Hrv3w5G.png" style={{objectFit:"contain",objectPosition:"center",paddingTop:"8px"}} height="95%"  width="100%"  alt="filims" /></div>
    </div>
    </div>
    <div>
    <div className='marquecard'>
      <div className='CONT'><img src="https://iili.io/Hrv3kzl.png" height="100%" width="100%"  alt="umbre" /></div>
    </div>
    </div>
    <div>
    <div className='marquecard'>
      <div className='CONT'><img src="https://iili.io/Hrv50aj.png"style={{objectFit:"contain",objectPosition:"center"}} height="100%" width="100%"  alt="kartavyam" /></div>
    </div>
    </div>
    <div className='marquecard'>
      <div className='CONT'><img src="https://iili.io/HrvuxYQ.png" style={{objectFit:"contain",objectPosition:"center"}} height="100%" width="100%"  alt="khojee" /></div>
    </div>
    <div className='marquecard'>
      <div className='CONT' ><img src="https://iili.io/HrvR2se.png" style={{objectFit:"contain",objectPosition:"center"}} height="100%" width="100%"  alt="bololoud" /></div>
    </div>
    <div className='marquecard'>
      <div className='CONT'><img src="https://iili.io/Hrv5RZ7.png" style={{objectFit:"contain",objectPosition:"center"}} height="100%" width="100%"  alt="games" /></div>
    </div>
    <div className='marquecard'>
      <div className='CONT'><img src="https://iili.io/HrvRa7R.png" style={{objectFit:"contain",objectPosition:"center"}} height="100%" width="100%"  alt="" /></div>
    </div>
  </Slider>
</div>
</div> */}