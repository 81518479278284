import React, { useEffect, useState } from "react";
import Navbar from "./Navbar/Navbar";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import About from "./About";
import Price from "./Price";
import "./css/Input.css"; 

const Internship = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [hamburger, sethambuger] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <header id="header" className="navbar fixed-top">
        <div className="container-fluid d-flex align-items-center justify-content-lg-between">
          <h1 className="logo me-auto me-lg-0">
            <a href="/">Uppthrive</a>
          </h1>
          {/* Uncomment below if you prefer to use an image logo */}
          {/* <a href="index.html" class="logo me-auto me-lg-0"><img src="assets/img/logo.png" alt="" class="img-fluid"></a>*/}
          <nav
            id="navbar"
            className={`navbar order-last order-lg-0  ${
              hamburger ? "navbar-mobile" : ""
            }`}
          >
            <ul>
              <li>
                <Link className="nav-link scrollto " to="/">
                  Home
                </Link>
              </li>
             
          
              <li>
                <Link className="nav-link scrollto" to="/">
                  Contact
                </Link>
              </li>
              <li>
                <Link className="nav-link scrollto" to={"/Internship"}>
                  Internship
                </Link>
              </li>
            </ul>
            <i
              className={`bi bi-list mobile-nav-toggle ${
                hamburger ? "bi-x" : "bi-list"
              }`}
              onClick={(e) => sethambuger(!hamburger)}
            />
          </nav>

          <div className="header-social-links d-flex align-items-center">
            <a href="#" className="facebook">
              <i className="bi bi-facebook" />
            </a>
            <a href="#" className="instagram">
              <i className="bi bi-instagram" />
            </a>
            <a href="#" className="linkedin">
              <i className="bi bi-linkedin" />
            </a>
          </div>
        </div>
      </header>
      <>
  {/* Hero Section */}
  <section id="hero2" className="hero section dark-background">
    <img src="assets/img/hero-bg.jpg" alt="" data-aos="fade-in" />
    <div className="container">
      <div className="row">
        <div className="col-lg-10">
          <h2 data-aos="fade-up" data-aos-delay={100}>
          "Transform Your Future with Cutting-Edge Industrial Training"
          </h2>
          <p data-aos="fade-up" data-aos-delay={200}>
          "Master skills in Website Development, E-Commerce Applications, Mobile App Development, Custom Software Solutions, AR/VR, and Generative AI. Learn, innovate, and build your dream career with our industry-aligned training programs."
          </p>
        </div>
        <div className="col-lg-5" data-aos="fade-up" data-aos-delay={300}>
        <a  href="#contact" className="btn-get-started scrollto">
        Contact us
      </a>
        </div>
      </div>
    </div>
  </section>
  {/* /Hero Section */}

  {/* About Section */}
  <section id="about" className="about section light-background">
    <div className="container" data-aos="fade-up" data-aos-delay={100}>
      <div className="row align-items-xl-center gy-5">
        <div className="col-xl-5 content">
          <h3>About Us</h3>
          <h2>"Empowering Tomorrow’s Innovators"</h2>
          <p>
          Our industrial training program is designed to equip you with the tools and expertise needed to excel in today’s technology-driven world. Whether you're a student, a budding developer, or a tech enthusiast, our hands-on training and mentorship from industry experts will ensure you’re ready to take on real-world challenges. Learn through live projects, interactive workshops, and a curriculum tailored to the latest trends in technology
          </p>
          <a href="#" className="read-more">
            <span>Read More</span>
            <i className="bi bi-arrow-right" />
          </a>
        </div>
        <div className="col-xl-7">
          <div className="row gy-4 icon-boxes">
            <div className="col-md-6" data-aos="fade-up" data-aos-delay={200}>
              <div className="icon-box">
              <i className="bi bi-command" />
                <h3>Hands-on Experience</h3>
                <p>
                Immerse yourself in practical learning with real-world tools and live projects to master industry-relevant skills effectively.
                </p>
              </div>
            </div>{" "}
            {/* End Icon Box */}
            <div className="col-md-6" data-aos="fade-up" data-aos-delay={300}>
              <div className="icon-box">
              <i className="bi bi-command" />
                <h3>Expert Mentorship</h3>
                <p>
                Learn from seasoned professionals who guide you through every step, ensuring you gain deep insights and actionable knowledge.
                </p>
              </div>
            </div>{" "}
            {/* End Icon Box */}
            <div className="col-md-6" data-aos="fade-up" data-aos-delay={400}>
              <div className="icon-box">
                <i className="bi bi-command" />
                <h3>Industry-Relevant Skills</h3>
                <p>
                Stay ahead of the curve with training that aligns with the latest trends and demands of the tech industry.
                </p>
              </div>
            </div>{" "}
            {/* End Icon Box */}
            <div className="col-md-6" data-aos="fade-up" data-aos-delay={500}>
              <div className="icon-box">
              <i className="bi bi-command" />
                <h3>Real-World Projects</h3>
                <p>
                Work on actual client-based projects to build a portfolio that showcases your expertise and problem-solving abilities.
                </p>
              </div>
            </div>{" "}
            {/* End Icon Box */}
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* /About Section */}

  {/* Services Section */}
  <section id="services" className="services section">
    {/* Section Title */}
    <div className="container section-title" data-aos="fade-up">
      <h2>Services</h2>
      <p>
      Unlock Limitless Opportunities with Our Training Programs
      </p>
    </div>
    {/* End Section Title */}
    <div className="container">
      <div className="row gy-4">
        <div className="col-lg-6 " data-aos="fade-up" data-aos-delay={100}>
          <div className="service-item d-flex">
            <div className="icon flex-shrink-0">
              <i className="bi bi-briefcase" />
            </div>
            <div>
              <h4 className="title">
                <a href="services-details.html" className="stretched-link">
                Website Development
                </a>
              </h4>
              <p className="description">
              Learn the art of building responsive, user-friendly websites from scratch.
              </p>
            </div>
          </div>
        </div>
        {/* End Service Item */}
        <div className="col-lg-6 " data-aos="fade-up" data-aos-delay={200}>
          <div className="service-item d-flex">
            <div className="icon flex-shrink-0">
              <i className="bi bi-card-checklist" />
            </div>
            <div>
              <h4 className="title">
                <a href="services-details.html" className="stretched-link">
                E-Commerce Web Apps
                </a>
              </h4>
              <p className="description">
              Create dynamic online stores that drive sales and engage customers.
              </p>
            </div>
          </div>
        </div>
        {/* End Service Item */}
        <div className="col-lg-6 " data-aos="fade-up" data-aos-delay={300}>
          <div className="service-item d-flex">
            <div className="icon flex-shrink-0">
              <i className="bi bi-bar-chart" />
            </div>
            <div>
              <h4 className="title">
                <a href="services-details.html" className="stretched-link">
                Mobile App Development
                </a>
              </h4>
              <p className="description">
              Build cutting-edge apps for iOS and Android platforms.
              </p>
            </div>
          </div>
        </div>
        {/* End Service Item */}
        <div className="col-lg-6 " data-aos="fade-up" data-aos-delay={400}>
          <div className="service-item d-flex">
            <div className="icon flex-shrink-0">
              <i className="bi bi-binoculars" />
            </div>
            <div>
              <h4 className="title">
                <a href="services-details.html" className="stretched-link">
                Custom Software Development
                </a>
              </h4>
              <p className="description">
              Design and develop tailor-made solutions for businesses.
              </p>
            </div>
          </div>
        </div>
        {/* End Service Item */}
        <div className="col-lg-6 " data-aos="fade-up" data-aos-delay={500}>
          <div className="service-item d-flex">
            <div className="icon flex-shrink-0">
              <i className="bi bi-brightness-high" />
            </div>
            <div>
              <h4 className="title">
                <a href="services-details.html" className="stretched-link">
                AR/VR Development
                </a>
              </h4>
              <p className="description">
              Dive into immersive technology and create experiences for tomorrow.
              </p>
            </div>
          </div>
        </div>
        {/* End Service Item */}
        <div className="col-lg-6 " data-aos="fade-up" data-aos-delay={600}>
          <div className="service-item d-flex">
            <div className="icon flex-shrink-0">
              <i className="bi bi-calendar4-week" />
            </div>
            <div>
              <h4 className="title">
                <a href="services-details.html" className="stretched-link">
                Generative AI Workshop
                </a>
              </h4>
              <p className="description">
              Unleash creativity with AI-powered tools and build innovative solutions.
              </p>
            </div>
          </div>
        </div>
        {/* End Service Item */}
      </div>
    </div>
  </section>
  {/* /Services Section */}
  {/* Features Section */}
  <section id="features" className="features section">
    {/* Section Title */}
    <div className="container section-title" data-aos="fade-up">
      <h2>Features</h2>
      <p>
      Experience unparalleled learning with a program designed to bridge the gap between theoretical knowledge and practical expertise.
      </p>
    </div>
    {/* End Section Title */}
    <div className="container">
      <div className="row gy-4 align-items-center features-item">
        <div
          className="col-lg-5 order-2 order-lg-1"
          data-aos="fade-up"
          data-aos-delay={200}
        >
          <h3>Comprehensive Learning Experience</h3>
          <p>
          Master the latest skills and technologies with a hands-on approach designed to make you industry-ready.
          </p>
          <ul>
            <li>
              <i className="bi bi-check" />{" "}
              <span>
              Live Projects: Gain practical exposure by working on real-world projects.
              </span>
            </li>
            <li>
              <i className="bi bi-check" />
              <span>
                {" "}
                Industry-Expert Trainers: Learn from professionals with years of experience.
              </span>
            </li>
            <li>
              <i className="bi bi-check" />{" "}
              <span>
              Modern Tools & Techniques: Work with the latest frameworks and technologies.
              </span>
              .
            </li>
            <li>
              <i className="bi bi-check" />{" "}
              <span>
              Personalized Mentorship: Receive one-on-one guidance for maximum growth.
              </span>
              .
            </li>
          </ul>
        
        </div>
        <div
          className="col-lg-7 order-1 order-lg-2 d-flex align-items-center"
          data-aos="zoom-out"
          data-aos-delay={100}
        >
          <div className="image-stack">
            <img
              src="assets/img/features-light-1.jpg"
              alt=""
              className="stack-front"
            />
            <img
              src="assets/img/features-light-2.jpg"
              alt=""
              className="stack-back"
            />
          </div>
        </div>
      </div>
      {/* Features Item */}
      <div className="row gy-4 align-items-stretch justify-content-between features-item ">
        <div
          className="col-lg-6 d-flex align-items-center features-img-bg"
          data-aos="zoom-out"
        >
          <img
            src="assets/img/features-light-3.jpg"
            className="img-fluid"
            alt=""
          />
        </div>
        <div
          className="col-lg-5 d-flex justify-content-center flex-column"
          data-aos="fade-up"
        >
          <h3>Your Gateway to Career Success</h3>
          <p>
          Join a training program that not only teaches you but also connects you to exciting career opportunities.
          </p>
          <ul>
            <li>
              <i className="bi bi-check" />{" "}
              <span>
              Placement Support: Receive guidance and connections to land your dream job
              </span>
            </li>
            <li>
              <i className="bi bi-check" />
              <span>
                {" "}
                Portfolio Development: Build a professional portfolio showcasing your projects.
              </span>
            </li>
            <li>
              <i className="bi bi-check" />{" "}
              <span>
              Flexible Schedules: Learn at your own pace with online and offline options.
              </span>
              .
            </li>
            <li>
              <i className="bi bi-check" />{" "}
              <span>
              Certification: Earn credentials to validate your skills and boost your resume.
              </span>
              .
            </li>
          </ul>
       
        </div>
      </div>
      {/* Features Item */}
    </div>
  </section>
  {/* /Features Section */}

  {/* contact  */}
  <section id="contact" class="contact">
      <div class="container">

        <div class="section-title">
          <h2>Contact</h2>
        
        </div>

     

       


          <div class=" mt-lg-0">

            <form  action="https://formsubmit.co/c8e9299aec25f7718fe23637b2ee6f06" method="POST" role="form" class="php-email-form">
              
              <div class="row">
                <div class="col-md-6 form-group">
                  <input type="text" name="name" class="form-control" id="name" placeholder="Your Name" required/>
                </div>
                <div class="col-md-6 form-group mt-3 mt-md-0">
                  <input type="email" class="form-control" name="email" id="email" placeholder="Your Email" required/>
                </div>
              </div>
              <div class="form-group mt-3">
                <input type="number" class="form-control inputcontrol" name="contact" id="contact" placeholder="Phone Number" required/>
              </div>
              <div class="form-group mt-3">
                <textarea class="form-control" name="message" rows="5" placeholder="Message" required></textarea>
              </div>
              <div class="my-3">
                <div class="loading">Loading</div>
                <div class="error-message"></div>
                <div class="sent-message">Your message has been sent. Thank you!</div>
              </div>
              <input type="hidden" name="_template" value="table"/>
              <input type="hidden" name="_captcha" value="false"></input>
              <input type="hidden" name="_next" value="http://localhost:3000/Thankyou"></input>
              <div class="text-center"><button type="submit">Send </button></div>
          
            </form>

          </div>

      

      </div>
    </section>
</>

    
      <Footer />
    </div>
  );
};

export default Internship;
