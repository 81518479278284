import React from 'react'

const Marketstrategies = () => {
  return (
    <div>
        <div className="section-title">
        <h2>Our Marketing Strategies</h2>
      <div className='market'>  <img  src="https://iili.io/Hrep1qJ.png"  width={"100%"} height={"100%"} alt="" /></div>
      </div>
    </div>
  )
}

export default Marketstrategies
