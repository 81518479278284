import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';


const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [hamburger, sethambuger] = useState(false)
  

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  
  
  return (
    <>
      {/* ======= Hero Section ======= */}
  <section id="hero">
    <div className="hero-container">
      <h1>Boost Your entrepreneurial Journey with us...</h1>
      <h2>
      Accelerate your entrepreneurial journey with us as your trusted partner. Our strategic guidance, innovative solutions, and unwavering support will fuel your growth and help you achieve extraordinary success in the business world.
      </h2>
      <a  href="#about" className="btn-get-started scrollto">
        Get Started
      </a>
    </div>
  </section>
  {/* End Hero */}
    {/* ======= Header ======= */}
    <header id="header" className={`navbar ${isScrolled ? 'fixed-top' : ''}`} >
      <div className='container-fluid d-flex align-items-center justify-content-lg-between'>
        <h1 className="logo me-auto me-lg-0">
          <a href="/">Uppthrive</a>
        </h1>
        {/* Uncomment below if you prefer to use an image logo */}
        {/* <a href="index.html" class="logo me-auto me-lg-0"><img src="assets/img/logo.png" alt="" class="img-fluid"></a>*/}
        <nav id="navbar" className={`navbar order-last order-lg-0  ${hamburger?"navbar-mobile":""}`}>
          <ul>
            <li>
              <a className="nav-link scrollto " href="#hero" >
                Home
              </a>
            </li>
            <li>
              <a className="nav-link scrollto" href="#about">
                About Us
              </a>
            </li>
            <li>
              <a className="nav-link scrollto" href="#pricing">
                Packages
              </a>
            </li>
            <li>
              <a className="nav-link scrollto " href="#clients">
                Partners
              </a>
            </li>
            <li>
              <a className="nav-link scrollto" href="#testimonials">
                Testimonials
              </a>
            </li>
            <li>
              <a className="nav-link scrollto" href="#contact">
                Contact
              </a>
            </li>
            <li>
              <Link className="nav-link scrollto" to={"/Internship"}>
                Internship
              </Link>
            </li>
          </ul>
          <i className={`bi bi-list mobile-nav-toggle ${hamburger?"bi-x":"bi-list"}`} onClick={e => sethambuger(!hamburger)}/>
        </nav>
     
        <div className="header-social-links d-flex align-items-center">
       
          <a href="#" className="facebook">
            <i className="bi bi-facebook" />
          </a>
          <a href="#" className="instagram">
            <i className="bi bi-instagram" />
          </a>
          <a href="#" className="linkedin">
            <i className="bi bi-linkedin" />
          </a>
        </div>
      </div>
    </header>
   
  </>
  
  )
}

export default Navbar
