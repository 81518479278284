import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from '../Home/Home'
import Thankyou from '../Thankyou'
import Internship from '../Internship'

const Allroutes = () => {
  return (
    <div>
       <Routes>
        <Route path='/' element={<Home/>} />
        
        <Route path='/Thankyou' element={<Thankyou/>} />
        <Route path='/Internship' element={<Internship/>} />
        </Routes>
    </div>
  )
}

export default Allroutes
