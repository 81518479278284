import React from "react";

const About = () => {
  return (
    <div>
      <>
        {/* ======= About Section ======= */}
        <section id="about" style={{ marginTop: "50px" }} className="about">
          <div className="container">
            <div className="section-title">
              <h2>About Us</h2>
            </div>
            <div className="item clean">
              <img
                src="https://iili.io/HrkQ9El.jpg"
                width="100%"
                height="100%"
                alt="image"
              />
              <div className="overlay">
                <span>"</span>
                <span>Driving Entrepreneurial Dreams</span>
                <span>
                  <p className="ovelayp">
                    we are passionate about creating and boosting entrepreneurs.
                    We believe in the power of ideas and the potential for
                    innovation. Through our comprehensive services, we provide
                    aspiring and established entrepreneurs with the necessary
                    tools, guidance, and support to turn their visions into
                    reality. From ideation and business planning to branding,
                    marketing, and beyond, we offer a holistic approach that
                    empowers entrepreneurs at every stage of their journey. Our
                    experienced team collaborates closely with our clients,
                    offering expertise, insights, and tailored strategies to
                    help them navigate the challenges of entrepreneurship and
                    achieve their goals. We are dedicated to unlocking the full
                    potential of each entrepreneur we work with, enabling them
                    to thrive and make a lasting impact in their respective
                    industries.
                  </p>
                </span>
              </div>
            </div>
          </div>
        </section>
        {/* End About Section */}
        {/* ======= About2 Section ======= */}
        <section id="about" className="about">
          <div className="container" data-aos="fade-up">
            <div className="row gx-0 mb-5">
              <div
                className="col-lg-6 d-flex flex-column justify-content-center"
                data-aos="fade-up"
                data-aos-delay={200}
              >
                <div className="content">
                  <h2>Professional website design.</h2>
                  <p>
                    Our team specializes in creating professional, user-friendly
                    websites that help startups establish a strong online
                    presence and attract their target audience. From sleek
                    designs to seamless functionality, we provide startups with
                    high-quality websites that showcase their brand, drive
                    conversions, and support their growth.
                  </p>

                  <p>
                    We help startups establish an impressive brand identity with
                    our custom website and logo design services.
                  </p>
                  {/* <div class="text-center text-lg-start">
      <a href="#" class="btn-read-more d-inline-flex align-items-center justify-content-center align-self-center">
        <span>Read More</span>
        <i class="bi bi-arrow-right"></i>
      </a>
    </div> */}
                </div>
              </div>
              <div
                className="col-lg-6 d-flex align-items-center"
                data-aos="zoom-out"
                data-aos-delay={200}
              >
                <img
                  src="https://iili.io/HreJ5Kb.jpg"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            {/* 2 */}
            <div className="row gx-0 mb-5">
              <div
                className="col-lg-6 d-flex align-items-center"
                data-aos="zoom-out"
                data-aos-delay={200}
              >
                <img
                  src="https://iili.io/Hrklyf1.jpg"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div
                className="col-lg-6 d-flex flex-column justify-content-center"
                data-aos="fade-up"
                data-aos-delay={200}
              >
                <div className="content">
                  <h2>Eye-catching logo design.</h2>
                  <p>
                    We design logos that reflect your startup's vision and
                    values. Our logos are simple, distinctive, and timeless,
                    making them ideal for establishing a memorable brand
                    identity.
                  </p>

                  <p>
                    Our Team creates eye-catching logo designs that capture
                    attention and leave a lasting impression, reflecting your
                    brand's unique identity. With a perfect blend of creativity
                    and strategic thinking, we craft logos that stand out in the
                    crowd and convey your brand's essence in a single glance.
                    Our expert designers bring your vision to life, delivering
                    visually stunning logos that not only look captivating but
                    also represent the values and personality of your business.
                  </p>
                  {/* <div class="text-center text-lg-start">
      <a href="#" class="btn-read-more d-inline-flex align-items-center justify-content-center align-self-center">
        <span>Read More</span>
        <i class="bi bi-arrow-right"></i>
      </a>
    </div> */}
                </div>
              </div>
            </div>
            {/* 3*/}
            <div className="row gx-0 mb-5">
              <div
                className="col-lg-6 d-flex flex-column justify-content-center"
                data-aos="fade-up"
                data-aos-delay={200}
              >
                <div className="content">
                  <h2>Powerful branding solution</h2>
                  <p>
                    Our powerful branding solution combines market research,
                    captivating visuals, and compelling messaging to create a
                    distinct brand identity that resonates with target audience.
                    From logo design to storytelling, we craft memorable brand
                    presence. With consistent representation across all
                    platforms, we boost brand recognition, customer loyalty, and
                    your competitive edge.
                  </p>
                  <p>
                    Whether it's through website design, social media campaigns,
                    or other marketing collateral, we ensure consistent and
                    impactful brand representation across all touchpoints. With
                    our powerful branding solution, your business will benefit
                    from increased brand recognition, customer loyalty, and a
                    competitive edge in the market.
                  </p>

                  {/* <div class="text-center text-lg-start">
      <a href="#" class="btn-read-more d-inline-flex align-items-center justify-content-center align-self-center">
        <span>Read More</span>
        <i class="bi bi-arrow-right"></i>
      </a>
    </div> */}
                </div>
              </div>
              <div
                className="col-lg-6 d-flex align-items-center"
                data-aos="zoom-out"
                data-aos-delay={200}
              >
                <img
                  src="https://iili.io/HrkUOG9.jpg"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            {/* 4 */}
            <div className="row gx-0">
              <div
                className="col-lg-6 d-flex align-items-center"
                data-aos="zoom-out"
                data-aos-delay={200}
              >
                <img
                  src="https://iili.io/HrkrFWB.jpg"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div
                className="col-lg-6 d-flex flex-column justify-content-center"
                data-aos="fade-up"
                data-aos-delay={200}
              >
                <div className="content">
                  <h2>Experts scaling strategies</h2>
                  <p>
                    Our expert scaling strategies encompass a comprehensive
                    approach to help businesses achieve sustainable growth. We
                    assess your current operations, systems, and infrastructure
                    to identify areas for optimization and scalability. Our
                    focus on performance optimization ensures that your systems
                    can handle increased user load with optimal speed and
                    responsiveness.
                  </p>

                  <p>
                    We design scalable architectures, implement effective data
                    management and analytics strategies, and provide guidance on
                    talent and team scaling. Continuous monitoring and
                    optimization are central to our approach, ensuring ongoing
                    scalability and performance. With our expertise, businesses
                    can confidently navigate the complexities of scaling, expand
                    their operations, and achieve long-term success.
                  </p>
                  {/* <div class="text-center text-lg-start">
      <a href="#" class="btn-read-more d-inline-flex align-items-center justify-content-center align-self-center">
        <span>Read More</span>
        <i class="bi bi-arrow-right"></i>
      </a>
    </div> */}
                </div>
              </div>
            </div>
            {/* 5*/}
            <div className="row gx-0 mb-5">
              <div
                className="col-lg-6 d-flex flex-column justify-content-center"
                data-aos="fade-up"
                data-aos-delay={200}
              >
                <div className="content">
                  <h2>Comprehensive support services</h2>
                  <p>
                    Our comprehensive support service provides businesses with
                    round-the-clock assistance, troubleshooting, and technical
                    expertise to ensure seamless operations and maximize
                    productivity. With our dedicated team of experts, we offer
                    prompt and reliable support tailored to your specific needs.
                  </p>
                  <p>
                    Our comprehensive team supports startups with buisiness
                    development marketing, budget management, and investor
                    relations. We are the every step of the way to ensure your
                    startup's success.
                  </p>

                  {/* <div class="text-center text-lg-start">
      <a href="#" class="btn-read-more d-inline-flex align-items-center justify-content-center align-self-center">
        <span>Read More</span>
        <i class="bi bi-arrow-right"></i>
      </a>
    </div> */}
                </div>
              </div>
              <div
                className="col-lg-6 d-flex align-items-center"
                data-aos="zoom-out"
                data-aos-delay={200}
              >
                <img
                  src="https://iili.io/HrkPPr7.jpg"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
        {/* End About2 Section */}
      </>
    </div>
  );
};

export default About;
