import React from "react";
import Navbar from "../Navbar/Navbar";
import About from "../About";
import Price from "../Price";
import Clients from "../Clients";
import Contact from "../Contact";
import Footer from "../Footer";
import Testimonials from "../Testimonials";
import Viedio from "../Viedio";
import Marketstrategies from "../Marketstrategies";
import Ourclients from "../Ourclients";
import Clint from "../Clint";
import Testimomialtwo from "../Testimomialtwo";
import "./Home.css"; 

const Home = () => {
  return (
    <div>
      <Navbar />

      <About />
      <Marketstrategies />
      <Price />
      {/* <Clients/> */}
      <Clint />
      {/* <Ourclients/> */}
      {/* <Testimonials/> */}
      <Testimomialtwo />
      <Viedio />
      <Contact />
      <Footer />
    </div>
  );
};

export default Home;
